
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';

import CommentComposer from '@/components/Comments/CommentComposer.vue';
import CommentViewer from '@/components/Comments/CommentViewer.vue';

import { CommentModel, DeliveryComment } from '@/models';
import { UserState } from '@/models/store';

@Component({
  name: 'CommentBlock',
  computed: {
    ...mapGetters('user', {
      userInfos: 'userInfos',
      userPhoto: 'userPhoto',
    }),
    ...mapGetters('comments', {
      comments: 'comments',
    }),
  },
  methods: {
    ...mapActions('comments', {
      setDeliveryComments: 'setDeliveryComments',
      postCommentDelivery: 'postCommentDelivery',
    }),
  },
  components: {
    CommentComposer,
    CommentViewer,
  },
})
export default class CommentBlock extends Vue {
  public userInfos!: UserState;
  public comments!: DeliveryComment;
  public setDeliveryComments!: (deliveryComment: DeliveryComment) => void;
  public postCommentDelivery!: ([message, entregaComentarioId]: [
    string,
    number | null,
  ]) => Promise<CommentModel>;

  public activeReplies: number[] = [];

  public composerLoading = false;
  public composerError = false;

  public commentDescription =
    // eslint-disable-next-line max-len
    'Colaborar é o primeiro passo para criar relacionamentos valiosos. Prestigie seu colega e contribua para o desenvolvimento.';

  @Prop({ default: true }) public showHeaderBlock!: boolean;

  get commentList(): CommentModel[] {
    return (this.comments && this.comments.data) || [];
  }

  set commentList(list: CommentModel[]) {
    const deliveryComments: DeliveryComment = { ...this.comments, data: list };
    this.setDeliveryComments(deliveryComments);
  }

  public replyComment(parentCommentIndex: number) {
    const alreadyIdActive = this.activeReplies.includes(parentCommentIndex);
    if (!alreadyIdActive) this.activeReplies.push(parentCommentIndex);
  }

  public sendComment(msg: string, comment?: CommentModel) {
    const comentarioId = comment ? comment.id : null;
    if (!comment) this.composerLoading = true;
    this.postCommentDelivery([msg, comentarioId])
      .then((commentPosted) => {
        this.addCommentPosted(commentPosted);
        if (comentarioId) this.removeActiveReplyById(comentarioId);
      })
      .catch(() => (this.composerError = true))
      .finally(() => {
        this.composerLoading = false;
      });
  }

  public addCommentPosted(commentPosted: CommentModel): void {
    const parentId = commentPosted.deliveryCommentId;

    if (parentId) {
      this.commentList = this.commentList.map((comment: CommentModel) => ({
        ...(comment.answers && parentId === comment.id
          ? { ...comment, answers: comment.answers.concat(commentPosted) }
          : comment),
      }));

      return;
    }

    this.commentList.unshift(commentPosted);
  }

  public removeActiveReplyById(parentCommentId: number) {
    const parentCommentIndex = this.commentList.findIndex(
      (c) => c.id === parentCommentId,
    );

    this.activeReplies = this.activeReplies.filter(
      (r) => r !== parentCommentIndex,
    );
  }
}
