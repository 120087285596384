
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Dictionary } from 'vue-router/types/router.d';

import CompleteArrow from '@/assets/icons/CompleteArrow.svg';

import CoverCarousel from '@/components/shared/CoverCarousel.vue';

import { RouteName, CoverDataList } from '@/models';

@Component({
  name: 'CarouselSection',
  components: {
    CoverCarousel,
    CompleteArrow,
  },
})
export default class CarouselSection extends Vue {
  @Prop({ required: true }) data: CoverDataList;
  @Prop({ required: true }) title: string;
  @Prop() route: RouteName;
  @Prop() params: Dictionary<string>;
  @Prop() description: string;

  public goTo() {
    this.$router.push({ name: this.route, params: this.params });
  }
}
