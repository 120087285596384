import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'TextareaAutoResize',
})
export default class TextareaAutoResize extends Vue {
  $refs!: {
    composer: HTMLTextAreaElement;
  };

  public composing() {
    this.$refs.composer.style.height = 'auto';
    this.$refs.composer.style.height = `${this.$refs.composer.scrollHeight + 2}px`;
  }
}
