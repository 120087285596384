
import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { Dictionary, Location } from 'vue-router/types/router.d';

import { BreadcrumbMeta, BreadcrumbModel } from '@/models/';

import { PathToValue } from '@/utils/PathToValue';

@Component({
  name: 'Breadcrumb',
})
export default class Breadcrumb extends Vue {
  @Prop() data: BreadcrumbModel;
  public breadcrumbList: BreadcrumbMeta[];

  created() {
    this.onRouteUpdate();
  }

  @Watch('$route')
  private onRouteUpdate() {
    this.breadcrumbList = this.$route.meta?.breadcrumb;
  }

  public clickedItem({ name, paramsKeyPath }: BreadcrumbMeta) {
    if (!name) return;

    let route: Location = { name };

    if (paramsKeyPath) {
      const params = Object
        .keys(paramsKeyPath)
        .reduce((acc: Dictionary<string>, key: string) => {
          acc[key] = String(PathToValue.getValue(paramsKeyPath[key], this.data));
          return acc;
        }, {});

      route = { ...route, params };
    }

    this.$router.push(route);
  }

  public breadcrumbItemName(path: string): string {
    const itemName = String(PathToValue.getValue(path, this.data));
    return itemName;
  }
}
