
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';
import { Route } from 'vue-router';

import Header from '@/components/Header/Header.vue';
import Profile from '@/components/Profile/Profile.vue';
import Footer from '@/components/Footer/Footer.vue';
import ButtonDefault from '@/components/shared/ButtonDefault.vue';
import MaterialBox from '@/components/shared/MaterialBox.vue';
import VideoPlayer from '@/components/shared/VideoPlayer.vue';
import CoverSelector from '@/components/Assignment/CoverSelector.vue';
import CarouselSection from '@/components/shared/CarouselSection.vue';
import CommentBlock from '@/components/Comments/CommentBlock.vue';
import AssignmentSection from '@/components/Assignment/AssignmentSection.vue';
import FloatingHeader from '@/components/Header/FloatingHeader.vue';
import Breadcrumb from '@/components/shared/Breadcrumb.vue';
import TextEditor from '@/components/shared/TextEditor.vue';

import ThumbsUp from '@/assets/icons/ThumbsUp.svg';
import Arquivo from '@/assets/icons/Arquivo.svg';
import BackArrow from '@/assets/icons/BackArrow.svg';
import LogoLinkedIn from '@/assets/icons/LogoLinkedIn.svg';

import {
  EntregaPublicada,
  Questao,
  QuestaoResposta,
} from '@/models/api';
import {
  RouteName,
  ProfileComponentBody,
  ButtonEvents,
  PageAnchor,
  RouterBehavior,
  CoverData,
  CoverEvents,
} from '@/models';

import { EventBus } from '@/utils/EventBus';
import { DateUtils } from '@/utils';

const { LIKE, COMMENT } = ButtonEvents;
const { COMMENTS } = PageAnchor;
const { SMOOTH } = RouterBehavior;

@Component({
  name: 'PublishedAssignment',
  computed: {
    ...mapGetters('user', {
      userId: 'userId',
      isStudent: 'isStudent',
      hasPublishedAssignments: 'hasPublishedAssignments',
    }),
    ...mapGetters('publishedAssignment', {
      breadcrumbs: 'breadcrumbs',
      deliveryId: 'deliveryId',
      coversList: 'coversList',
      profileData: 'profileData',
      questions: 'questions',
      reaction: 'reaction',
      liked: 'liked',
      requesting: 'requesting',
      delivery: 'delivery',
    }),
    ...mapGetters('headerDisplay', {
      smallView: 'smallView',
    }),
  },
  methods: {
    ...mapActions('publishedAssignment', {
      likeOrUnlike: 'likeOrUnlike',
      getAssignmentToView: 'getAssignmentToView',
    }),
  },
  components: {
    Header,
    Footer,
    ButtonDefault,
    CommentBlock,
    MaterialBox,
    VideoPlayer,
    CoverSelector,
    CarouselSection,
    AssignmentSection,
    ThumbsUp,
    Arquivo,
    BackArrow,
    FloatingHeader,
    LogoLinkedIn,
    Breadcrumb,
    Profile,
    TextEditor,
  },
})
export default class PublishedAssignment extends Vue {
  private accessCountDebounce!: number;
  private userId!: number;
  private startedScrolling!: boolean;
  public delivery!: EntregaPublicada;
  public hasPublishedAssignments: boolean;
  public isStudent!: boolean;
  public questions!: Questao[];
  public likeOrUnlike!: () => void;
  private getAssignmentToView!: (id: number) => void;

  public ProfileBody = ProfileComponentBody;
  public showFloatingProfile = false;
  public defaultImage = require('@/assets/img/no-profile-photo.png');
  public route = RouteName.ASSIGNMENT_DISCIPLINE_LIST;

  $refs: {
    startPoint: HTMLElement;
    finishPoint: HTMLElement;
    floatingElement: HTMLElement;
  };

  get showCommentsAndScore(): boolean {
    const { usuario, comentario, nota } = this.delivery;

    return Boolean(
      (this.userId === usuario.id || !this.isStudent) && (nota || comentario),
    );
  }

  get dataPublicacao(): string {
    return DateUtils.toString(this.delivery.dataPublicacao);
  }

  created() {
    this.subscribeToEvents();
  }

  mounted() {
    this.startedScrolling = this.$route.hash === COMMENTS;
  }

  updated() {
    if (this.startedScrolling) this.keepScrolling();
  }

  beforeDestroy() {
    clearTimeout(this.accessCountDebounce);
    this.unsubscribeToEvents();
  }

  @Watch('$route')
  public onRouteUpdate(route: Route) {
    const deliveryId = Number(route.params.entregaId);
    this.getAssignmentToView(deliveryId);
  }

  public goToMyself({ id }: CoverData) {
    this.$router.push({
      name: RouteName.PUBLISHED_ASSIGNMENT,
      params: { entregaId: String(id) },
    });
  }

  public keepScrolling() {
    if (this.startedScrolling) {
      this.startedScrolling = false;
      this.goToComments();
    }
  }

  private subscribeToEvents() {
    window.addEventListener('scroll', this.checkItemsVisible);
    EventBus.$on(LIKE, this.likeOrUnlike);
    EventBus.$on(COMMENT, this.goToComments);
    EventBus.$on(CoverEvents.NAVIGATE, this.goToMyself);
  }

  private unsubscribeToEvents() {
    window.removeEventListener('scroll', this.checkItemsVisible);
    EventBus.$off(LIKE, this.likeOrUnlike);
    EventBus.$off(CoverEvents.NAVIGATE, this.goToMyself);
    EventBus.$off(COMMENT, this.goToComments);
  }

  public goBack() {
    this.$router.back();
  }

  private goToComments() {
    window.location.hash = COMMENTS;
    const comments = this.$refs.finishPoint.offsetTop;
    window.scrollTo({ left: 0, top: comments, behavior: SMOOTH });
  }

  public checkItemsVisible() {
    if (!this.delivery) return;

    const { startPoint, finishPoint } = this.$refs;

    const ceil = startPoint.getBoundingClientRect().bottom;
    const floor = finishPoint.getBoundingClientRect().top - window.innerHeight;

    this.showFloatingProfile = ceil < 0 && floor > 0;
  }

  public getResposta(respostaId: number): string {
    return this.delivery.questoes.find(
      (questao: QuestaoResposta) => questao.trabalhoQuestaoId === respostaId,
    )?.resposta ?? '&nbsp;';
  }

  public openLink(url: string) {
    window.open(url, '_blank');
  }
}
