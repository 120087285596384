
import { Component, Prop, Watch } from 'vue-property-decorator';

import TextareaAutoResize from '@/mixins/TextareaAutoResize';

import Avatar from '@/components/shared/Avatar.vue';
import ButtonDefault from '@/components/shared/ButtonDefault.vue';

@Component({
  name: 'CommentComposer',
  components: {
    Avatar,
    ButtonDefault,
  },
})
export default class CommentComposer extends TextareaAutoResize {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: false }) errorOnPublish: boolean;
  @Prop({}) photo!: string;

  $refs!: {
    composer: HTMLTextAreaElement;
  };

  public message = '';

  public publish() {
    this.$emit('onPublish', this.message);
  }

  @Watch('loading')
  onLoadingChange() {
    if (!this.loading && !this.errorOnPublish) this.message = '';
  }
}
