
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapActions } from 'vuex';
import Avatar from '@/components/shared/Avatar.vue';
import CommentComposer from '@/components/Comments/CommentComposer.vue';
import { CommentInProgress } from '@/models/store';
import { EventBus } from '@/utils';
import { CommentModel, CommentPublishEvents } from '@/models';

@Component({
  name: 'CommentViewer',
  methods: {
    ...mapActions('comments', {
      replyLoading: 'replyLoading',
      replyError: 'replyError',
      deleteCommentInProgress: 'deleteCommentInProgress',
      setCommentInProgress: 'setCommentInProgress',
    }),
  },
  components: {
    Avatar,
    CommentComposer,
  },
})
export default class CommentViewer extends Vue {
  public deleteCommentInProgress!: (
    commentInProgress: CommentInProgress,
  ) => void;
  public setCommentInProgress!: (id: number) => void;
  public replyLoading!: (id: number) => Promise<boolean>;
  public replyError!: (id: number) => Promise<boolean>;

  @Prop({}) public commentData: CommentModel;
  @Prop({ default: false }) public isAReply: boolean;
  @Prop({ default: false }) public replyActive: boolean;
  @Prop({}) public composerPhoto!: string;

  public errorOnPublishReply = false;
  public loadingPublishReply = false;
  public defaultImage = require('@/assets/img/no-profile-photo.png');

  mounted() {
    this.subscribeToEvents(!this.isAReply);
  }

  beforeDestroy() {
    this.unsubscribeToEvents(!this.isAReply);
  }

  subscribeToEvents(canSub: boolean) {
    if (canSub) {
      const { id } = this.commentData;
      EventBus.$on(CommentPublishEvents.LOADING + id, this.checkLoading);
      EventBus.$on(CommentPublishEvents.ERROR + id, this.checkError);
    }
  }

  unsubscribeToEvents(canUnSub: boolean) {
    if (canUnSub) {
      const { id } = this.commentData;
      EventBus.$off(CommentPublishEvents.LOADING + id, this.checkLoading);
      EventBus.$off(CommentPublishEvents.ERROR + id, this.checkError);
    }
  }

  public checkLoading() {
    const { id } = this.commentData;
    this.replyLoading(id).then((load) => (this.loadingPublishReply = load));
  }

  public checkError() {
    const { id } = this.commentData;
    this.replyError(id).then((error) => (this.errorOnPublishReply = error));
  }

  public handleReply() {
    this.$emit('onReply');
  }

  public publishReply(message?: string) {
    const { id } = this.commentData;
    this.setCommentInProgress(id);
    this.$emit('onPublishReply', message, this.commentData);
  }
}
